import React  from "react"
import Layout from "../../../components/layout";
import { Helmet } from "react-helmet"; 
import {  SunIcon } from "@heroicons/react/solid"; 
import { SRLWrapper } from "simple-react-lightbox";
import { StaticImage } from "gatsby-plugin-image";
import { FlatGallery } from "../../../components/FlatGallery";
import { Flats } from "../../../components/Flats";

const ground = [
    {nazev: "Hala", plocha: "13,25"  },
    {nazev: "Komora", plocha: "3,39"  },
    {nazev: "Obývací pokoj", plocha: "25,97"  },
    {nazev: "Kuchyňský kout", plocha: "7,78"  },
    {nazev: "Technická místnost", plocha: "5,96"  },
    {nazev: "WC", plocha: "1,65"  },
    {nazev: "Terasa", plocha: "15,00"  },
]

const first = [
    {nazev: "Schodiště", plocha: "11,08"  },
    {nazev: "Ložnice", plocha: "10,93"  },
    {nazev: "Ložnice", plocha: "12,99"  },
    {nazev: "Ložnice", plocha: "14,07"  },
    {nazev: "Šatna", plocha: "2,64"  },
    {nazev: "Koupelna", plocha: "7,44"  },
]


const Byt1Page = ({location}) => {
     

  return (
  <>
       <Helmet>
      <meta charSet="utf-8" />
      <title>Byt 1 | Domy Trio</title> 
    </Helmet>
      <Layout location={location}> 
   

      
<main >
    <div className="mx-auto w-full   relative z-30 ">
       <div className="mx-auto max-w-6xl w-full p-8 lg:px-14 z-auto  backdrop-filter  bg-white bg-opacity-90 relative shadow-md border border-gray-200 ">    
             <svg className="absolute w-60 h-60 text-yellow-400 top-0 right-0 ">
                <polygon points="0,0 300,0 300,300" class="triangle" fill="currentColor" />
                <SunIcon width="50" x="160" y="-70"  fill="#ffffff" />
            </svg>
            <div className="relative">
           <div className="">
               <h1 className="text-xl lg:text-3xl font-merri mb-10 border-b border-yellow-400 pb-3">Byt 1</h1> 
               <div className="p-5 pt-0">
                        <Flats trio={2} flat={1} />
                </div> 
           </div>
           <div className="relative w-full grid lg:grid-cols-3"> 
                <div className="px-5 lg:col-span-2">
                    <div className="py-2">Plocha místností</div>
                    <div className="bg-gray-100 text-sm divide-y divide-gray-300 border border-gray-300 h-auto ">
                        {ground.map((place,placeIdx) => (
                            <div key={placeIdx} className="grid grid-cols-2 space-x-4 gap-2   ">
                                <div className="p-2">
                                    {place.nazev}
                                </div>
                                <div className="p-2 text-right">
                                    {place.plocha} m<sup>2</sup>
                                </div> 
                            </div>
                        ))}
                    </div>
                    <div className="bg-gray-100 text-sm divide-y divide-gray-300 border border-gray-300 h-auto mt-5">
                        {first.map((place,placeIdx) => (
                            <div key={placeIdx} className="grid grid-cols-2 space-x-4 gap-2  ">
                                <div className="p-2">
                                    {place.nazev}
                                </div>
                                <div className="p-2 text-right">
                                    {place.plocha} m<sup>2</sup>
                                </div> 
                            </div>
                        ))}
                    </div>
                    <div className="py-2 font-bold">Celková užitná plocha: 132,15 m<sup>2</sup></div>
                </div>
                 <div>
                 <SRLWrapper>
                    <div className=""> 
                        <div className="py-2">Umístění bytu v rámci domu Trio</div>
                        <div className={"overflow-hidden shadow-md border hover:border-gray-500 "}>
                            <StaticImage  src={'../../../images/2byt11.jpg'} 
                            className="cursor-pointer object-cover min-h-full " 
                            alt="Umístění bytu v rámci domu Trio"
                            />
                        </div> 
                    </div>
                </SRLWrapper>
                </div>
            </div>
            <div className="relative w-full my-10 "> 
                
                <SRLWrapper>
                    <div className="grid gap-3 grid-cols-2"> 
                        <div>
                        <div className="py-2">Přízemí</div>
                            <div className={"overflow-hidden shadow-md border hover:border-gray-500 "}>
                                <StaticImage  src={'../../../images/2byt12.jpg'} 
                                className="cursor-pointer object-cover min-h-full " 
                                alt="Přízemí"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="py-2">Podkroví</div>
                            <div className={"overflow-hidden shadow-md border hover:border-gray-500 "}>
                                <StaticImage  src={'../../../images/2byt13.jpg'} 
                                className="cursor-pointer object-cover min-h-full " 
                                alt="Podkroví"
                                />
                            </div> 
                        </div> 
                    </div>
                </SRLWrapper>
                
            </div>

            <div className="py-2 text-xl">Vizualizace - návrh řešení interiéru</div>
            <FlatGallery />
                   
        </div>
    </div>

    </div>
  </main>
    <div >
        
  </div>
    </Layout>
  </>
  )
}

export default Byt1Page
 